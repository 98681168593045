.button {
  padding: 16px 32px;
  border-radius: 32px;
  font-size: 18px;
  transition: all 0.3s ease;

  @each $key, $value in $colors {
    &.button--#{$key} {
      background-color: $value;
      color: $white;
    }
  }

  &.button--indiblue {
    &:hover {
      background-color: $midnight;
      color: $white;
    }
  }
}
