.brand {
  .brand__concepts {
    li {
      margin-bottom: 8px;
    }
  }

  .brand__title {
    margin: 40px 0px 18px;
  }

  span {
    color: $indiblue;
  }
}
