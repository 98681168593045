.intro {
  max-width: 736px;
  @include response(medium) {
    margin-top: -70px !important;
  }

  .lottie__header {
    border-bottom: 1px solid $cloudy;
    width: 100vw;
    margin-left: -16px;
    padding-bottom: 48px;

    @include response(small) {
      padding-bottom: 30px;
      margin-left: -37px;
    }

    @include response(medium) {
      margin-right: -40px;
    }
    @include response(large) {
      width: 100%;
      margin-left: 0px;
    }
  }

  .introduction__subtitle {
    padding-top: 64px;
    max-width: 640px;

    @include response(medium) {
      padding-top: 48px;
    }
  }

  .intro__company {
    max-width: 640px;
    .intro__italic {
      max-width: 630px;
      font-size: 18px;
      font-weight: 400;
    }
    span {
      font-style: italic;
    }

    li {
      list-style-type: numeric;
    }
  }

  .component__principles {
    margin-top: 56px;
    margin-bottom: 80px;

    @include response(medium) {
      margin-top: 48px;
      margin-bottom: 64px;
    }

    img {
      width: 100%;
    }
  }

  .messaging {
    h3 {
      margin-top: 0;
      margin-bottom: 32px;
      margin-right: 0;
    }
    .case__word {
      display: flex;
      flex-direction: column;
      @include response(medium) {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  .guide-lines {
    margin-top: 52px;
    h3 {
      margin-bottom: 32px;
    }
  }

  .introduction__element {
    padding-left: 12px;
    padding-right: 12px;

    .row * {
      padding-left: 0px;
      padding-right: 0px;
    }

    .element__title {
      margin-bottom: 32px;
    }

    .element__subtitle {
      padding-bottom: 24px;
      font-size: 24px;
    }

    .element {
      border-top: 1px solid $indiblue;
      border-bottom: 1px solid $indiblue;

      &:nth-child(2) {
        border-bottom: 0px;
        @include response(medium) {
          border-bottom: 1px solid $indiblue;
        }
      }

      &:nth-child(4) {
        border-bottom: 1px solid $indiblue;
        border-top: 0px;
        @include response(medium) {
          border-bottom: 1px solid $indiblue;
        }
      }

      &:nth-child(5) {
        border-bottom: 1px solid $indiblue;
        border-top: 0px;
        @include response(medium) {
          border-bottom: 1px solid $indiblue;
        }
      }

      @include response(large) {
        height: 147px;
      }

      .element__name {
        text-transform: uppercase;
        font-weight: 700;
        padding-top: 16px;
        padding-bottom: 8px;
        &:nth-child(2) {
          border-top: 0;
        }
      }

      .element__paragraph {
        margin-bottom: 24px;

        @include response(large) {
          font-size: 18px;
        }
        span {
          color: $indiblue;
        }
      }

      @include response(large) {
        max-width: 352px;
        width: 100%;
      }
    }
    .guidelines {
      margin-top: 77px;
      @include response(medium) {
        margin-top: 49px;
      }
    }
    .alineacion {
      @include response(medium) {
        padding-left: 32px;
      }
    }
  }
}
