@import "./variables";

// breakpoint:start
@mixin response($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    +"Please make sure it is defined in `$breakpoints` map.";
  }
}
// breakpoint:end
