.grammar {
  li {
    max-width: 640px;
  }
  .italic {
    font-style: italic;
  }
  .bullet {
    font-size: 12px;
  }
  .grammar-span {
    color: $indiblue;
  }

  .subrayado {
    text-decoration: underline;
  }

  .grammar__content {
    .content__subtitle {
      margin-bottom: 16px;
      margin-top: 40px;
    }

    .item-azul {
      color: $indiblue;
      padding-left: 24px;
    }

    .item-rojo {
      color: $dont;
      padding-left: 24px;
    }
  }
}
