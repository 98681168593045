//Import fuente
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;600;700&display=swap");
//Import fuerte secundaria
@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;300;400;500;600;700&display=swap");

$grid-breakpoints: (
	xs: 0,
	sm: 768px,
	md: 1024px,
	lg: 1200px,
	xl: 1440px,
	xxl: 1920px,
);

// Import libraries here 👇🏻
@import "bootstrap";
// @import "bootstrap/scss/mixins";
// @import "bootstrap/scss/functions";
// @import "bootstrap/scss/variables";
// @import "bootstrap/scss/utilities";
// @import "bootstrap/scss/grid";

// Reset
@import "reset.css";

// Utils
@import "./utils/variables";
@import "./utils/mixins";
@import "./utils/helpers";

// Layouts
@import "./layouts/layout-primary";

// Components
@import "./components/menu";
@import "./components/container";
@import "./components/button";
@import "./components/list";
@import "./components/intro-component";
@import "./components/footer";
@import "./components/backtotop";
@import "./components/footer-navigation";
@import "./components/audio-card";
@import "./components/audio-player";
@import "./components/menu-accordeon";
@import "./components/design-system-card";

//BRAND
@import "./screens/home";
@import "./screens/principles";

//MESSAGING
@import "./screens/introduction";
@import "./screens/brand-vision";
@import "./screens/voice-chart";
@import "./screens/do-dont";
@import "./screens/case-studies";
@import "./screens/grammar";
@import "./screens/word-list";

//GUIDELINES
@import "./screens/logotype";
@import "./screens/icons";
@import "./screens/typography";
@import "./screens/color";
@import "./screens/photography";
@import "./screens/illustration";
@import "./screens/sonic-branding";
@import "./screens/motion";
@import "./screens/3d-elements";
@import "./screens/social-media";

body {
	color: $stout;
	font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
		"Segoe UI Symbol";
	font-size: 20px;
	line-height: 28px;
	transition: all 0.3s ease;

	@include response(medium) {
		font-size: 24px;
	}

	// Quitamos los márgenes que vienen con bootstrap.
	h1,
	h2,
	h3,
	h4,
	h5 {
		margin-bottom: 0;
	}

	h1,
	h2 {
		&.archivo {
			font-family: "Archivo";
		}
	}

	h1 {
		font-size: 40px;
		line-height: 48px;
		font-weight: 400;
		margin-bottom: 32px;

		@include response(large) {
			font-size: 56px;
			line-height: 67px;
		}
	}

	h2 {
		font-size: 34px;
		line-height: 42px;
		font-weight: 400;

		@include response(large) {
			font-size: 42px;
			line-height: 50px;
		}
		&title-d-system{
			margin-bottom: 32px;
		}
	}

	h3 {
		font-size: 24px;
		line-height: 30px;
		font-weight: 400;

		@include response(large) {
			font-size: 32px;
			line-height: 38px;
		}

		&.medium {
			font-weight: 500;
		}
	}

	h4 {
		font-size: 16px;
		line-height: 24px;
	}

	a {
		font-size: 18px;
		line-height: 24px;
		color: $indiblue;
		text-decoration: none;
	}

	p {
		max-width: 640px;
		margin: 0;
		padding: 0;
		font-size: 20px;
		line-height: 28px;

		&.large {
			font-size: 20px;
			line-height: 28px;
		}

		&.small {
			font-size: 18px;
			line-height: 26px;
		}

		&.detail {
			font-size: 16px;
			line-height: 24px;
		}

		&.do {
			color: $stone;
		}

		&.dont {
			color: $dont;
		}
	}

	.indi-blue {
		&:hover {
			color: $midnight;
		}
	}

	ul {
		padding: 0;
	}

	li {
		font-size: 18px;
		line-height: 26px;
		margin-bottom: 8px;
	}
}

.no-scroll {
	position: relative; // for Safari
	height: 100% !important;
	overflow: hidden !important;
	
}
