.audio__container {
  margin-left: -18px;
  .audio__description {
    color: $stone;
    margin-bottom: 16px;
    font-size: 18px;
    padding: 0;
    margin: 32px 0px 16px 0px;
    @include response(medium) {
      margin: 48px 0px 20px 0px;
    }
  }
  .audio__player {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    margin-left: 18px;

    .card__play {
      position: relative;
      z-index: 9999;
      width: 54px;
      height: 54px;
      right: 18px;
      top: 7;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $indiblue;
      border-radius: 50%;
      border: 0;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    }

    a {
      &:hover {
        * {
          stroke: white;
        }
      }
    }

    span {
      color: white;
      font-size: 18px;
    }

    .audio__items {
      max-width: 311px;
      width: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 32px;
      background-color: $midnight;
      border-radius: 8px;
      max-width: 311px;
      padding: 8px 12px 9px 39px;

      .progress__bar {
        margin-left: 16px;
        max-width: 172px;
        width: 100%;
      }

      .audio__time {
        color: white;
        font-size: 18px;
        line-height: 26px;
        margin-left: 16px;
      }

      .audio__arrow {
        margin-bottom: 4px;
      }
    }
  }
}
