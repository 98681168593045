.intro__component {
  display: block;
  margin-bottom: 32px;

  &.img100 {
    img {
      width: 100%;
      height: auto;
    }
  }

  &.bkg {
    background-color: $cream;
  }

  &.mb-4 {
    margin-bottom: 32px !important;
  }

  &.big {
    .intro__images {
      padding: 24px 24px 0 24px;

      @include response(small) {
        padding: 30px 24px 16px 24px;
      }
    }
  }

  &.small {
    .intro__images {
      padding: 24px 24px 0 24px;

      @include response(small) {
        padding: 20px 24px 0 24px;
      }
    }
  }

  &.big__motion {
    .intro__images {
      padding: 24px 99px 0 99px;

      @include response(small) {
        padding: 30px 43px 0 43px;
      }

      @include response(large) {
        padding: 32px 43px 2px 43px;
      }
    }
  }

  .intro__images {
    .mobile {
      margin: 0;
      display: block;
      @include response(small) {
        display: none;
      }
    }
    .desktop {
      margin: 0;
      display: none;
      @include response(small) {
        display: block;
      }
    }
  }

  .intro__content {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    background-color: $cream;
    p {
      color: $midnight;
    }

    span {
      &:hover {
        background-color: $midnight;
      }
    }

    .intro__button {
      background-color: $indiblue;
      border-radius: 24px;
      border: 0px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      font-size: 24px;
      margin: 0;
      padding: 0;
    }
  }
}
