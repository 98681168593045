.sonic__branding {
  max-width: 832px;
  width: 100%;
  .row {
    margin: 0;
  }

  .sonic__title {
    margin-bottom: 24px;

    @include response(medium) {
      margin-bottom: 32px;
    }
  }

  .topics__title {
    margin-top: 65px;

    @include response(medium) {
      margin-top: 55px;
      margin-bottom: 8px;
    }
  }

  .sonic__track {
    margin-top: 48px;
    margin-bottom: 56px;
    p {
      max-width: 832px;
    }

    @include response(medium) {
      margin-top: 40px;
      margin-right: 22px;
    }

    @include response(large) {
      margin-right: 0px;
    }

    .track {
      background-color: $midnight;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 16px 16px 40px 16px;

      @include response(medium) {
        flex-direction: row;
        padding: 32px 32px 32px 38px;
      }

      .track__info {
        color: white;
        p {
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
        .track__download {
          display: flex;
          justify-content: space-between;
          align-items: center;
          h3 {
            margin: 0;
          }
        }
      }
    }
  }

  .branding__use {
    margin-top: 72px;

    h3 {
      margin-bottom: 16px;
      @include response(medium) {
        margin-bottom: 8px;
      }
    }
    @include response(medium) {
      margin-top: 80px;
    }
    .sfx__title {
      margin-top: 48px;
      @include response(medium) {
        margin-top: 56px;
      }
    }
    .sonic__player {
      margin-top: 40px;
      margin-left: 7px;
      @include response(small) {
        margin-left: 6px;
        margin-top: 69px;
      }
    }
  }

  .sonic__animated {
    margin-top: 64px;
    margin-bottom: 72px;

    @include response(medium) {
      margin-top: 83px;
      margin-bottom: 120px;
    }

    .animate__track {
      margin-top: 32px;
      margin-bottom: 48px;
      margin-left: 18px;

      @include response(medium) {
        margin-top: 56px;
        margin-bottom: 40px;
        margin-left: 20px;
      }
    }

    .in--use {
      margin-top: 48px;
      margin-bottom: 24px;
      color: $stone;

      @include response(medium) {
        margin-top: 40px;
        margin-bottom: 32px;
      }
    }
    .sonic__logo {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $midnight;

      @include response(medium) {
        width: 479px;
      }

      svg {
        width: 100%;
        height: auto;
        padding: 130px 100px;
      }
    }
  }
}
