.voice {
  @include response(medium) {
    padding-right: 35px;
  }
  @include response(large) {
    padding-right: 0;
  }

  .voice-introduction {
    margin-top: 24px;
    margin-bottom: 40px;

    @include response(small) {
      margin-top: 32px;
      margin-bottom: 40px;
    }
  }

  .voice__cuadro {
    span {
      font-weight: 500;
    }
    @include response(small) {
      border-bottom: 1px solid grey;
    }

    .cuadro__title {
      border-bottom: 1px solid $indiblue;
      display: none;
      flex-direction: row;
      justify-content: space-evenly;
      color: $indiblue;
      text-transform: uppercase;

      :first-child {
        margin-left: 125px;
      }
      :nth-last-child(2) {
        margin-left: 65px;
      }
      :last-child {
        margin-right: 19px;
      }

      @include response(small) {
        display: flex;
      }
    }
    .start-grilla {
      margin-top: 32px;
      margin-bottom: 32px;
      @include response(small) {
        margin: 16px 0px;
      }
    }
    .title--indiblue {
      text-transform: uppercase;
      color: $indiblue;
      margin-bottom: 16px;

      @include response(small) {
        display: none;
      }
    }

    .paragraph--grilla {
      margin-bottom: 35px;
      @include response(small) {
        margin: 16px 0px;
      }
    }

    .motion {
      border-bottom: 2px solid $indiblue;
      @include response(small) {
        border: 0;
      }
    }
  }

  .tone {
    .tone-ex {
      margin-top: 32px;
      margin-bottom: 16px;
    }
    .tone-paragraph {
      margin-bottom: 8px;
    }
  }
}
