.three-dimensions__container {
  .paragraph {
    margin-top: 24px;
  }

  .people--icons {
    .people {
      img {
        margin-bottom: 32px;
        @include response(medium) {
          margin-right: 16px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .object--icons {
    .object {
      text-align: center;

      @include response(small) {
        text-align: start;
      }
      img {
        width: 30%;
        margin-bottom: 32px;
        margin-right: 10px;

        @include response(small) {
          width: 20%;
        }

        @include response(medium) {
          text-align: left;
          margin-right: 25px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .three-dimensions__downloads {
    .downloads__paragraph {
      margin-bottom: 42px;
    }
  }
}
