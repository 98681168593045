.case__content {
  margin: 40px 0px;
  li {
    margin: 8px 0px;
    span {
      color: $indiblue;
      margin-right: 10px;
    }
  }
}
