.social-media__container {
  section {
    margin-bottom: 72px;
    @include response(medium) {
      margin-bottom: 80px;
    }
  }
  .social-media__typography {
    .typography__explanation {
      margin-bottom: 71px;

      @include response(medium) {
        margin-bottom: 40px;
      }
    }

    .type-scale {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 32px 22px;
      background-color: $cream;
      white-space: nowrap;
      margin-bottom: 8px;

      .col-12 {
        p {
          padding: 0;
        }
      }

      p {
        margin-top: 24px;

        @include response(small) {
          margin-top: 0;
        }
      }

      @include response(small) {
        height: 64px;
        padding: 8px 0px 8px 1px;
      }

      @include response(medium) {
        height: 68px;
        padding: 8px 15px 8px 1px;
      }

      .row {
        width: 100%;
        align-items: center;
        .col-sm-2 {
          padding-right: 0px;
          // padding-left: 20px;
        }

        @include response(small) {
          p:not([class*="t-"]) {
            width: 100%;
            text-align: center;
          }
        }
      }

      .t-1 {
        font-size: 44px;
        line-height: 52px;
        white-space: nowrap;
        margin: 0 !important;
      }

      .t-2 {
        font-size: 32px;
      }

      .t-3 {
        font-size: 24px;
        font-weight: 400;
        margin: 0;
      }

      .t-4 {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 1%;
        margin: 0;
      }

      .t-5 {
        line-height: 24px;
        font-weight: 500;
      }

      .t-6 {
        font-size: 24px;
        line-height: 52px;
        font-weight: 500;
        margin: 0;
        @include response(small) {
          font-size: 26px;
        }
        @include response(large) {
          font-size: 30px;
        }
      }

      .t-7 {
        font-size: 28px;
        line-height: 52px;
        font-weight: 500;
        margin: 0;
        @include response(small) {
          font-size: 26px;
        }
        @include response(large) {
          font-size: 32px;
        }
      }

      .t-8 {
        font-size: 30px;
        line-height: 52px;
        font-weight: 500;
        @include response(small) {
          font-size: 26px;
        }
        @include response(large) {
          font-size: 32px;
        }
      }
      .t-9 {
        font-size: 32px;
        line-height: 52px;
        font-weight: 500;
        @include response(small) {
          font-size: 26px;
        }
        @include response(large) {
          font-size: 36px;
        }
      }

      .t-10 {
        font-size: 36px;
        line-height: 52px;
        font-weight: 500;
        @include response(small) {
          font-size: 26px;
        }
        @include response(large) {
          font-size: 38px;
        }
      }
    }

    .typography__body {
      margin-top: 24px;
      margin-bottom: 40px;
    }

    .headings {
      margin-top: 24px;
    }
  }

  .colors {
    .colors__examples {
      margin-top: 24px;
      .example {
        width: 100%;
        padding: 16px 8px;
        margin-bottom: 8px;

        span {
          margin-left: 20px;
        }

        @include response(medium) {
          padding: 16px 19px;
          margin-bottom: 20px;
        }
      }

      .border {
        border: 1px solid #000000;
      }
    }
  }

  .grid {
    .grid__examples {
      margin-top: 24px;
      img {
        width: 100%;
        margin-bottom: 12px;
        @include response(medium) {
          margin-bottom: 0;
        }
      }
    }
  }

  .illustrations {
    .illustration__example {
      margin-top: 24px;
      img {
        width: 100%;
        margin-bottom: 16px;

        @include response(small) {
          margin-bottom: 14px;
        }
      }

      .dont {
        border-top: 1px solid $dont;
        padding-top: 16px;
        margin-bottom: 24px;

        @include response(medium) {
          margin-bottom: 0;
        }
      }

      .do {
        border-top: 1px solid $stone;
        padding-top: 16px;
      }
    }
  }

  .case-studies {
    .case-studies__examples {
      margin-top: 24px;
      img {
        margin-bottom: 10px;
        width: 100%;
        @include response(medium) {
          margin-bottom: 24px;
        }
      }
    }
    .covers__description {
      img {
        width: 40%;
        margin-top: 24px;
      }
      .examples__description {
        .description {
          margin-bottom: 8px;
        }
      }
    }
  }

  .do-dont__container {
    .do-dont__examples {
      .example {
        margin-bottom: 24px;

        @include response(medium) {
          margin-bottom: 72px;
        }

        img {
          width: 100%;
          margin-bottom: 24px;
        }

        .do {
          border-top: 1px solid $stone;
          padding-top: 16px;
        }

        .dont {
          border-top: 1px solid $dont;
          padding-top: 16px;

          @include response(medium) {
            margin-bottom: 0;
            // margin-bottom: 24px;
          }
        }
      }
    }
  }

  .post {
    .post__examples {
      margin-top: 24px;

      .example {
        img {
          width: 100%;
          margin-bottom: 24px;
        }
      }
    }
  }
}
