.logotype {
  @include response(medium) {
    padding-right: 40px;
  }
  @include response(large) {
    padding-right: 0;
  }
  .logotype__header {
    margin-top: 72px;

    @include response(medium) {
      margin-top: 0;
    }
    svg {
      width: 100%;
      height: auto;
      margin: 24px 0px 72px;
      @include response(medium) {
        margin: 16px 0px 80px;
      }
    }
  }
  .mini-box {
    padding: 50px 62px;
    border: 1px solid #d9d9da;
  }
  .bkg--cream {
    border: 1px solid $cream;
  }
  .bkg--supernova {
    border: 1px solid $supernova;
  }
  .bkg--indiblue {
    border: 1px solid $indiblue;
  }
  .bkg--midnight {
    border: 1px solid $midnight;
  }
  .bkg--stout {
    border: 1px solid $stout;
  }

  .img__logotype {
    width: 100%;
    margin: 16px 0px 72px;
    @include response(medium) {
      margin: 16px 0px 80px;
    }
  }

  .logotype__backgrounds {
    .examples {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include response(small) {
        flex-direction: row;
      }
      .first {
        border: 1px solid #d9d9da;
      }

      .box {
        width: 100%;
        height: 117px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include response(small) {
          width: calc(100% / 3);
          height: 96px;
        }

        @include response(medium) {
          height: 117px;
        }

        svg {
          @include response(small) {
            height: 32px;
          }
        }
      }
    }

    .backgrounds__descriptions {
      margin-bottom: 48px;
      @include response(medium) {
        margin-bottom: 32px;
      }
    }
    .description {
      color: $indiblue;
      margin: 16px 0px 48px;
      @include response(medium) {
        margin: 8px 0px 32px;
      }

      &.red {
        color: $dont;
      }

      &:last-child {
        margin-bottom: 80px;
      }
    }
  }

  .our__icon {
    margin-bottom: 80px;

    .our__icon__paragraph {
      margin-bottom: 32px;

      @include response(medium) {
        margin-bottom: 16px;
      }
    }

    .icons-examples {
      display: flex;
      flex-direction: column;

      @include response(small) {
        flex-direction: row;
      }

      .col-large {
        margin-bottom: 16px;

        @include response(small) {
          width: 40%;
          margin: 0;
        }
      }

      .col-small {
        display: flex;
        flex-wrap: wrap;

        @include response(small) {
          width: 60%;
          margin-left: 16px;
        }

        .cuadrado {
          width: 50%;
          padding-bottom: 50%;

          &.bg--indiblue,
          &.bg--midnight,
          &.bg--stout {
            svg {
              * {
                fill: $white;
              }
            }
          }

          svg {
            width: auto;
            height: 68px;

            @include response(small) {
              height: 54px;
            }

            @include response(medium) {
              height: 65px;
            }

            * {
              fill: $stout;
            }
          }

          @include response(small) {
            width: calc(100% / 3);
            padding-bottom: calc(100% / 3);
          }
        }
      }

      .cuadrado {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 100%;

        &.bg--white {
          outline: 1px solid $cloudy;
          outline-offset: -1px;
        }

        svg {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
        }
      }

      .mini-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }

      .prueba {
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        @include response(medium) {
          flex-direction: row;
          margin-top: 0;
          margin-left: 19px;
        }
      }
    }
  }

  .p {
    svg {
      margin-right: 96px;
      margin-bottom: 26px;
      margin-top: 19px;
    }
    white-space: wrap;

    @include response(small) {
      svg {
        margin-right: 386px;
        margin-bottom: 18px;
      }
    }

    @include response(medium) {
      white-space: nowrap;
      svg {
        margin-right: 16px;
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    span {
      font-weight: 500;
    }
  }

  .scale {
    .indi-scale {
      width: calc(100% + 16px);
      overflow: scroll;
      margin: 16px 0px 80px;

      @include response(small) {
        width: 100%;
      }

      @include response(large) {
        width: calc(100% + 16px);
        overflow: hidden;
      }
    }
  }

  .logo__construction {
    .example__explanation {
      margin-bottom: 16px;
    }
    svg {
      margin-bottom: 39px;
      @include response(small) {
        width: 100%;
        height: 279px;
      }
      @include response(medium) {
        height: 336px;
      }
    }
  }

  .incorrect__uses {
    margin-bottom: 102px;
    @include response(small) {
      margin-bottom: 71px;
    }
    @include response(medium) {
      margin-bottom: 73px;
    }
    .incorrect__examples {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;

      @include response(small) {
        flex-direction: row;
        justify-content: space-between;
        align-items: unset;
      }
      .example {
        width: 256px;

        @include response(small) {
          width: 220px;
        }

        @include response(large) {
          width: 256px;
        }

        svg {
          width: 256px;
          @include response(small) {
            width: 220px;
          }
          @include response(large) {
            width: 256px;
          }
        }
        .example__explanation {
          color: $dont;
          margin-top: 8px;
        }
      }
    }
  }
}
