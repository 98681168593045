ul.bulleted-list {
  padding-left: 8px;

  li {
    &::before {
      content: "";
      width: 4px;
      height: 4px;
      position: relative;
      top: -4px;
      display: inline-block;
      margin-right: 8px;
      border-radius: 50%;
      background-color: $stout;
    }
  }
}
