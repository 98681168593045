.container {
  max-width: 832px;
  padding: 0 16px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;

  @include response(small) {
    padding: 0px 40px;
  }
  @include response(medium) {
    padding: 0;
  }
}
