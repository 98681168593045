.photography {
  .photo__paragraph {
    margin-bottom: 40px;
  }
  img {
    width: 100%;
  }
  .photo__description {
    color: $indiblue;
    margin-top: 8px;
    margin-bottom: 32px;

    &.red {
      color: $dont;
    }
  }
  .bad__examples {
    margin-top: 80px;
  }
}
