.principles {
  p {
    max-width: 736px;
  }
  .principles__subtitle {
    text-transform: uppercase;
  }
  .list {
    margin-top: 27px;
    margin-bottom: 27px;
  }
  .principles__concepts {
    margin-top: 75px;
    display: flex;
    flex-direction: column;

    @include response(small) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    .concept {
      width: 352px;
      margin-bottom: 70px;
      @include response(small) {
        width: 320px;
      }

      @include response(medium) {
        width: 352px;
      }

      svg {
        width: 64px;
        height: 64px;
        margin-bottom: 40px;
      }

      .principles__title {
        text-transform: uppercase;
        color: $indiblue;
        margin-bottom: 8px;
      }

      &:first-child {
        margin-right: 32px;
      }

      &:nth-child(3) {
        margin-right: 32px;
      }
    }
  }
}
