.ds__card__container {
  background: $cream;
  padding: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  bottom: 0;
  right: 0;
  left: 0;

  @include response(medium) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 75px;
  }

  @include response(large) {
    padding: 24px 44px 41px 44px;
  }

  .ds-icon-mobile {
    margin-right: 16px;

    @include response(small) {
      margin-right: 0;
      margin-bottom: 16px;
    }

    @include response(large) {
      display: none;
    }
  }

  .ds-icon-desktop {
    display: none;
    @include response(large) {
      display: block;
      margin-top: -100px;
      margin-bottom: 16px;
    }
  }

  .ds__content {
    @include response(small) {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    @include response(medium) {
      flex-direction: column;
    }
    p {
      max-width: 161px;
      width: 100%;
      @include response(small) {
        max-width: 298px;
      }
      @include response(large) {
        max-width: 207px;
      }
    }

    a {
      white-space: nowrap;

      svg {
        margin-left: 8px;
      }

      &:hover {
        color: $midnight !important;
        svg {
          * {
            fill: $midnight !important;
          }
        }
      }
    }
  }
}
