.illustration {
  h4 {
    text-transform: uppercase;
  }
  .illus__subtitle {
    margin-bottom: 80px;
  }

  .illus__color {
    margin-bottom: 16px;
    @include response(medium) {
      margin-bottom: 8px;
    }
  }

  .illus__color--subtitle {
    margin-bottom: 40px;
    @include response(medium) {
      margin-bottom: 32px;
    }
  }

  .color__container {
    width: 100%;
    height: 56px;
    background-color: $midnight;
    color: white;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    p {
      margin-left: 19px;
      span {
        font-weight: 500;
      }
    }
    @include response(medium) {
      width: 95%;
    }

    @include response(large) {
      width: 100%;
    }
  }

  .nova {
    background-color: $supernova;
    color: $midnight;
  }

  .indi {
    background-color: $indiblue;
    color: white;
  }

  .melon {
    background-color: $watermelon;
    color: $midnight;
  }
  .cream {
    background-color: $cream;
    color: $midnight;
  }

  .illustration__desc {
    margin-bottom: 32px;

    &--grey {
      color: $stone;
    }
    &:last-child {
      margin-bottom: 80px;
    }
  }
  .indi__icons {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin-top: 56px;
    margin-bottom: 72px;
    @include response(medium) {
      flex-direction: row;
    }

    .svg-first {
      margin-bottom: 72px;

      @include response(medium) {
        margin-bottom: 0;
      }
    }

    .svg-last {
      @include response(medium) {
        margin-bottom: 30px;
        margin-left: 25px;
      }
      @include response(large) {
        margin-left: 50px;
      }
    }

    .col-12 {
      width: auto;
    }
  }

  .examples__title {
    text-transform: uppercase;
    margin-bottom: 16px;
    font-weight: 700;
  }

  .line__examples {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid $indiblue;
    padding-bottom: 24px;
    max-width: 764px;
    margin: 0;

    @include response(small) {
      flex-direction: row;
      justify-content: center;
    }

    @include response(medium) {
      padding-bottom: 16px;
      svg {
        margin: 0;
      }
    }

    svg {
      flex-shrink: inherit;
      align-self: center;
      margin-bottom: 48px;
      &:last-child {
        margin-bottom: 0px;
      }

      @include response(small) {
        margin-right: 24px;
        margin-bottom: 0;
      }
    }

    &.red {
      border-bottom: 1px solid $dont;
    }
  }
  .line {
    color: $stone;
    padding-top: 16px;

    &.outline {
      color: $dont;
      margin-bottom: 80px;
    }
  }

  .people__character {
    .character-image {
      width: 100%;
      height: auto;
      margin-top: 16px;
      @include response(medium) {
        max-width: 736px;
      }
    }
    .style__construction {
      h4 {
        font-weight: 700;
      }
      margin-top: 72px;
      @include response(medium) {
        margin-top: 56px;
      }
    }
    .head__construction {
      width: 343px;
      height: 564px;
      background-color: $cream;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 16px auto 83px;
      span {
        margin: 64px 0px 64px;
        font-size: 40px;
      }

      @include response(small) {
        flex-direction: row;
        width: 688px;
        height: 155px;
        span {
          margin-right: 88px;
          margin-left: 88px;
        }
      }

      @include response(medium) {
        width: 736px;
        height: 155px;
        margin-bottom: 54px;
        margin-right: 0;
        margin-left: 0;
      }
    }

    .head__style {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-content: center;
      @include response(medium) {
        flex-direction: row;
        align-items: center;
        justify-content: start;
      }
      svg {
        padding-bottom: 6px;
        flex-shrink: inherit;
        @include response(medium) {
          width: 100%;
          height: auto;
        }
      }

      .svg {
        @include response(medium) {
          width: 256px;
          height: 314px;
        }

        &--2 {
          @include response(medium) {
            width: 173px;
            height: 320px;
            margin-right: 70px;
            margin-left: 70px;
          }
        }

        &--3 {
          @include response(medium) {
            width: 173px;
            height: 320px;
          }
        }
      }

      .p-design {
        border-top: 1px solid #05051e;
        padding-top: 8px;
        margin-bottom: 47px;
        @include response(medium) {
          margin-bottom: 40px;
        }
      }
    }
    .p__structure {
      margin-bottom: 72px;
      @include response(medium) {
        margin-bottom: 56px;
        margin-top: 80px;
      }
    }
  }
  .body__structure {
    .structure__title {
      margin-bottom: 8px;
      font-weight: 700;
    }
    .structure {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 32px;

      svg {
        margin-right: 10px;
        margin-left: 10px;
        margin-bottom: 100px;
      }
      @include response(little) {
        justify-content: start;
        svg {
          margin-bottom: 50px;
          margin-right: 10px;
          &:nth-child(4) {
            margin-right: 25px;
          }
        }
      }
      @include response(small) {
        justify-content: space-between;
      }
      @include response(large) {
        display: block;
        svg {
          margin-bottom: 56px;
          margin-right: 59px;

          &:first-child {
            margin-left: 0px;
          }

          &:last-child {
            margin-right: 0px;
          }
          &:nth-child(4) {
            margin-right: 59px;
          }
        }
      }
    }
  }
  .arms__hands {
    h4 {
      font-weight: 700;
    }
    .arms__explanation {
      margin-top: 8px;
      margin-bottom: 48px;
    }
    .arms__hands__examples {
      margin-top: 48px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      svg {
        max-width: 343px;
        margin-bottom: 64px;
      }

      @include response(medium) {
        display: block;
        margin-top: 32px;
        svg {
          max-width: 320px;
          margin-bottom: 48px;
          &:first-child {
            margin-right: 72px;
          }
          &:nth-child(3) {
            margin-right: 92px;
          }
        }
      }
      @include response(large) {
        svg {
          max-width: 393px;
        }
      }
    }

    @include response(medium) {
      .arms__explanation {
        margin-bottom: 32px;
      }
    }
  }

  .legs {
    h4 {
      font-weight: 700;
    }
    .legs__explanation {
      margin-top: 8px;
      margin-bottom: 48px;

      @include response(medium) {
        margin-bottom: 32px;
      }
    }
    .legs__examples {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 48px;

      svg {
        margin-bottom: 56px;
      }

      @include response(medium) {
        flex-direction: row;
        svg {
          margin-right: 64px;
          margin-bottom: 32px;
        }
      }
    }
  }
  .objects {
    .objects__explanation {
      margin-top: 8px;
      margin-bottom: 20px;

      @include response(medium) {
        margin-bottom: 16px;
      }
    }
    .objects__example {
      margin-bottom: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include response(small) {
        flex-direction: row;
      }
      svg {
        &:first-child {
          margin-bottom: 41px;
          @include response(medium) {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  .color_in_use {
    .color__explanation {
      margin-bottom: 48px;

      @include response(medium) {
        margin-bottom: 16px;
      }
    }

    .color__examples {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 48px;

      svg {
        margin-bottom: 32px;
      }
      @include response(small) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @include response(medium) {
          justify-content: start;
        }

        svg {
          margin-right: 32px;
          &:nth-child(3) {
            margin-right: 0px;
          }
        }
      }
      @include response(medium) {
        svg {
          &:nth-child(3) {
            margin-right: 32px;
          }
        }
      }
      @include response(large) {
        svg {
          &:nth-child(3) {
            margin-right: 0px;
          }
        }
      }
    }
  }
  .downloads {
    a {
      font-weight: 500;
    }
    .downloads__explanation {
      margin-bottom: 61px;

      @include response(medium) {
        margin-bottom: 47px;
      }
    }
  }
}
