.footer__navigation {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;

  a {
    font-size: 16px;
    color: $stout;
    font-weight: 400;
  }

  p {
    font-size: 18px;
    color: $stout;
    font-weight: 500;
  }

  @include response(medium) {
    margin-bottom: 43px;
  }

  .navigation__item {
    display: flex;
    align-items: center;

    &:hover {
      .navigation__arrow {
        background-color: $midnight;
        background: cover;
      }
    }

    &--right {
      text-align: right;
    }

    p {
      line-height: 22px;
    }

    .navigation__arrow {
      background-color: $indiblue;
      border-radius: 24px;
      border: 0px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      transition: all 0.3s ease;

      &--left {
        svg {
          position: relative;
          left: -1px;
          transform: rotate(180deg);
        }
      }

      &--two {
        margin-left: 8px;
        margin-right: 0;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        :hover {
          background-color: $midnight;
        }
      }
    }
  }
}
