.menu {
	position: relative;
	width: 100%;
	margin-bottom: 56px;

	@include response(medium) {
		width: 220px;
		height: 100vh;
		position: fixed;
		display: flex;
		flex-wrap: wrap;
		border-right: 1px solid #f1f1f5;
		overflow-y: auto;
	}

	@include response(large) {
		width: 320px;
	}

	.menu__controls {
		width: 100%;
		padding: 24px 16px;
		z-index: 9999;
		position: relative;
		transition: all 0.3s ease;

		&--active {
			background-color: $midnight;
		}

		@include response(small) {
			padding: 24px 40px;
		}

		@include response(medium) {
			align-self: flex-start;
			padding: 56px 24px 0 32px;
		}
	}

	.menu__logo {
		z-index: 9998;
		position: relative;
		transition: all 0.3s ease;

		svg {
			width: 114px;
			height: 24px;

			@include response(large) {
				width: 147px;
				height: 32px;
			}
		}

		&--activo {
			svg {
				* {
					fill: white;
				}
			}
		}
	}

	.menu__toggler {
		width: 24px;
		float: right;
		display: flex;
		padding: 0;
		flex-direction: column;
		border: 0;
		background: none;
		padding-top: 7px;
		transition: all 0.3s ease;
		position: relative;

		@include response(medium) {
			display: none;
		}
		&.menu__toggler--activo {
			span {
				background-color: white;
				&:first-child {
					top: 6px;
					transform: rotate(45deg);
				}

				&:nth-child(2) {
					opacity: 0;
				}

				&:last-child {
					top: -8px;
					transform: rotate(-45deg);
				}
			}
		}
		span {
			width: 100%;
			height: 3px;
			position: relative;
			margin-bottom: 4px;
			transition: all 0.3s ease;
			background-color: $midnight;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.menu__content {
		width: 100%;
		left: 0;
		right: 0;
		top: calc(100% - 20px);
		position: absolute;
		bottom: 0;
		padding: 30px 0 135px 16px;
		color: white;
		opacity: 0;
		visibility: hidden;
		transition: all 0.3s ease;
		z-index: 9990;
		height: calc(100vh - 130px);
		overflow: auto;
		overflow-y: scroll;

		@include response(medium) {
			height: calc(100vh - 90px);
			position: static;
			display: flex;
			flex-wrap: wrap;
			padding: 50px 0 0 0;
			opacity: 1;
			visibility: visible;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		&--activo {
			opacity: 1;
			visibility: visible;
			background-color: $midnight;
			
		}

		.ds__section {
			right: 0;
			left: 0;
			bottom: 0;
			z-index: 9999;
			position: fixed;

			@include response(medium) {
				width: 100%;
				position: relative;
				align-self: flex-end;
			}
		}

		.menu__list {
			color: white;

			@include response(small) {
				margin-top: 80px;
				margin-left: 24px;
			}

			@include response(medium) {
				width: 100%;
				margin-top: 0;
			}
		}
	}
}
