.typography {
  padding: 24px;
  .typography__title {
    margin-bottom: 72px !important;
    @include response(medium) {
      margin-bottom: 32px !important;
    }
  }
  .typography__intro {
    margin-bottom: 48px;
    @include response(medium) {
      margin-bottom: 16px;
    }
  }
  .typography__dm {
    margin-bottom: 102px;

    @include response(medium) {
      margin-bottom: 98px;
    }
    .dm__title {
      font-size: 45px;
      font-weight: 500;
      line-height: 100%;
      margin-bottom: 32px;
      @include response(medium) {
        font-size: 72px;
        margin-bottom: 24px;
      }
    }
    .dm {
      margin: 32px 0px 43px;
      @include response(medium) {
        margin: 24px 0px 35px;
      }
    }
    .button {
      font-weight: 500;
    }
  }

  .typography__archivo {
    font-family: "Archivo";
    margin-bottom: 102px;

    @include response(medium) {
      margin-bottom: 98px;
    }

    h3 {
      font-weight: 600;
      font-size: 35px;
      line-height: 48px;
      @include response(medium) {
        font-weight: 600;
        font-size: 44px;
        line-height: 48px;
      }
    }

    .archivo {
      margin: 32px 0px 43px;
      @include response(medium) {
        margin: 24px 0px 35px;
      }
    }

    .button {
      font-family: "DM Sans";
      font-weight: 500;
    }
  }

  .type-scale {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 22px;
    background-color: $cream;
    white-space: nowrap;
    margin-bottom: 8px;

    .col-12 {
      p {
        padding: 0;
      }
    }

    p {
      margin-top: 24px;

      @include response(small) {
        margin-top: 0;
      }
    }

    @include response(small) {
      height: 64px;
      padding: 8px 0px 8px 1px;
    }

    @include response(medium) {
      height: 68px;
      padding: 8px 15px 8px 1px;
    }

    .row {
      width: 100%;
      .col-sm-2 {
        padding-right: 0px;
        padding-left: 20px;
      }

      @include response(small) {
        p:not([class*="t-"]) {
          width: 100%;
          text-align: center;
        }
      }
    }

    .t-1 {
      font-size: 44px;
      line-height: 52px;
      white-space: nowrap;
      font-weight: 500;
      margin: 0 !important;
    }
    .t-2 {
      font-size: 32px;
      font-weight: 500;
    }
    .t-3 {
      margin: 0;
      font-size: 24px;
      font-weight: 400;
    }
    .t-4 {
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 1%;
    }
    .t-5 {
      font-size: 16px;
      line-height: 24px;
    }
    .t-6 {
      font-size: 12px;
    }
  }
  .type__paragraph {
    margin-bottom: 72px;
    @include response(medium) {
      margin-bottom: 16px;
    }
  }

  .typography__title {
    margin-bottom: 32px;
  }
  .typography__subtitle {
    margin-bottom: 16px;
  }

  .paragraph__body {
    margin-top: 68px;
    @include response(medium) {
      margin-top: 80px;
    }

    .example {
      padding: 16px 0px;
    }
    .sub__section {
      h3 {
        margin: 0;
        padding: 0;
        font-weight: 400;
        font-size: 24px;
        margin-bottom: 16px;
      }
      background-color: $cream;
      p {
        font-size: 16px;
        line-height: 24px;
      }
      padding: 48px 33px;
      @include response(small) {
        padding: 48px 16px 48px 29px;
      }
      @include response(medium) {
        padding: 48px 80px;
      }

      .sub__small__paragraph {
        font-size: 12px;
        margin-top: 16px;
        line-height: 20px;
      }
    }
  }

  .incorrect__uses {
    margin-top: 80px;
    .incorrect__title {
      margin-bottom: 52px;
      @include response(medium) {
        margin-bottom: 16px;
      }
    }
    .incorrect__examples {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;
      align-content: center;
      @include response(small) {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
      }
      .example {
        width: 256px;
        font-family: "Archivo";
        margin-bottom: 32px;

        svg {
          width: 256px;
          height: 144px;
        }

        @include response(small) {
          svg {
            width: 208px;
          }
          width: 208px;
        }

        @include response(large) {
          svg {
            width: 256px;
            height: 144px;
          }
          width: 256px;
        }
        .example__explanation {
          color: $dont;
          margin-top: 1px;

          @include response(medium) {
            margin-top: 8px;
          }
        }
      }
    }
  }
}
