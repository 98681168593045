@each $key, $value in $colors {
  .color--#{$key} {
    color: $value;
  }

  .bg--#{$key} {
    background-color: $value;
  }
}

@each $key, $value in $weights {
  .fw--#{$key} {
    font-weight: $key;
  }
}
