.motion {
  @include response(medium) {
    padding-right: 25px;
  }

  @include response(large) {
    padding-right: 0px;
  }
  h3 {
    font-weight: 500;
  }
  .motion__title {
    margin-bottom: 24px;
    @include response(medium) {
      margin-bottom: 32px;
    }
  }
  .motion__subtitle {
    margin-top: 68px;
    @include response(medium) {
      margin-top: 40px;
    }
  }
  .motion__logo {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $indiblue;
    margin-bottom: 32px;
    margin-top: 32px;
    @include response(medium) {
      margin-top: 16px;
    }

    svg {
      width: 100%;
      height: auto;
      margin: 94px 62px;
      background-color: $indiblue;

      @include response(medium) {
        width: 308px;
        margin: 84px 260px;
      }
    }
  }

  .motion__rectangulos {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    @include response(medium) {
      flex-direction: row;
      margin-bottom: 32px;
    }

    .rectangulo {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $indiblue;

      @include response(medium) {
        width: calc(100% / 3);
      }

      svg {
        width: 219px;
        height: auto;
        margin: 39px 79px;

        @include response(medium) {
          width: 308px;
          margin: 39px 46px;
        }
      }

      &.bg--midnight {
        background-color: $midnight;
      }

      &.bg--stout {
        background-color: $stout;
      }
    }
  }

  .motion__cuadrados {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 72px;
    @include response(medium) {
      flex-direction: row;
      margin-bottom: 97px;
    }
    .cuadrado {
      width: 50%;
      height: auto;
      background-color: $indiblue;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $indiblue;

      &:nth-child(2) {
        border: 1px solid $cloudy;
      }

      @include response(medium) {
        width: calc(100% / 4);
      }

      svg {
        height: auto;
        margin: 50px 64px;

        @include response(medium) {
          margin: 61px 78px;
        }
      }
    }

    .bg--supernova {
      background-color: $supernova;
    }

    .bg--cream {
      background-color: $cream;
    }

    .bg--stout {
      background-color: $cream;
    }

    .fill--white {
      svg {
        * {
          fill: white;
        }
      }
    }
  }

  .motion__animacion {
    .col__animacion {
      margin-top: 70px;
      margin-bottom: 100px;
      display: flex;
      flex-direction: column;
      justify-items: flex-start;
      @include response(small) {
        flex-direction: row;
        margin-top: 43px;
        margin-bottom: 108px;
      }
      svg {
        margin-bottom: 10px;

        @include response(small) {
          margin-right: 30px;
        }

        @include response(medium) {
          margin-right: 130px;

          &:nth-child(2) {
            margin-right: 0px;
          }
        }
      }
    }
  }

  .motion__animacion__personajes {
    svg {
      width: 100%;
      height: auto;
      margin-top: 32px;

      @include response(small) {
        margin-top: 16px;
      }
    }
  }
}
