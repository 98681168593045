.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $cloudy;
  padding: 40px 16px 0 16px;
  max-width: 832px;
  margin-top: 72px;

  @include response(medium) {
    padding-right: 20px;
    padding-left: 0;
  }
  @include response(large) {
    padding-right: 0px;
  }
  p {
    font-size: 16px;
    color: $stone;
    white-space: nowrap;
  }

  .footer__update {
    display: flex;
    flex-direction: column;

    @include response(medium) {
      flex-direction: row;
      p {
        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }
}
