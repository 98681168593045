.accordion {
	border: 0;
	background: #082c4e;

	@include response(medium) {
		background: white;
	}
	&.active {
		.list__page__title {
			.title {
				svg {
					transform: rotate(-90deg);
					@include response(medium) {
						* {
							stroke: $midnight;
						}
					}
				}

				@include response(medium) {
					color: $midnight;
				}
			}
		}

		.list__items {
			display: block;
		}
	}

	.list__page__title {
		color: white;
		text-transform: uppercase;
		letter-spacing: 0.2px;
		font-weight: 700;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include response(medium) {
			color: $indiblue;
		}

		.title {
			border: 0;
			background: transparent;
			text-align: start;
			color: $white;
			text-transform: uppercase;
			font-size: 16px;
			max-width: 350px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-weight: 700;

			@include response(small) {
				max-width: 680px;
			}
			@include response(medium) {
				color: $indiblue;
				max-width: 231px;
			}

			@include response(large) {
				max-width: 255px;
			}

			svg {
				@include response(medium) {
					* {
						stroke: $indiblue;
					}
				}
			}
		}
	}

	.list__items {
		display: none;
		margin-top: 12px;

		@include response(medium) {
			margin-top: 16px;
		}

		.link--active {
			color: $supernova;

			&::before {
				color: $supernova;

				@include response(medium) {
					color: $midnight;
				}
			}

			@include response(medium) {
				color: $midnight;
			}
		}

		a {
			color: white;
			font-size: 16px;
			line-height: 26px;

			&::before {
				content: "•";
				color: white;
				margin-right: 8px;
				margin-left: 5px;
				list-style-position: inside;

				@include response(small) {
					font-size: 20px;
					line-height: 28px;
				}

				@include response(medium) {
					color: $stone;
				}
			}

			&:hover {
				color: $supernova;
			}

			@include response(medium) {
				font-size: 18px;
				color: $stone;

				&:hover {
					color: $midnight;

					&::before {
						color: $midnight;
					}
				}
			}
		}
	}
}
