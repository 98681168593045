.icons {
  .icons__96 {
    display: grid;
    grid-template-columns: auto auto;
    gap: 80px;
    align-items: flex-start;
    justify-content: center;

    @include response(small) {
      grid-template-columns: auto auto auto;
      svg {
        margin-right: 35px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    @include response(medium) {
      display: block;
    }
  }
  .detail {
    margin-top: 10px;
  }
  .icons__64 {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include response(small) {
      justify-content: space-evenly;
    }
    @include response(medium) {
      display: block;
      svg {
        margin-right: 80px;
      }
    }
  }
  .icons__complementary {
    margin-top: 80px;
  }
}
