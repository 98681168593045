// colors:start
$white: #fff;
$black: #000;
$indiblue: #3098f3;
$midnight: #082c4e;
$supernova: #ffcc00;
$watermelon: #fe6571;
$cream: #f2eae2;
$stone: #747481;
$cloudy: #d9d9da;
$dont: #e72525;
$stout: #05051e;

$colors: (
  white: $white,
  black: $black,
  indiblue: $indiblue,
  midnight: $midnight,
  supernova: $supernova,
  watermelon: $watermelon,
  cream: $cream,
  stone: $stone,
  dont: $dont,
  stout: $stout,
);
// colors:end

// breakpoints:start
$breakpoints: (
  little: 414px,
  small: 768px,
  medium: 1024px,
  large: 1200px,
  extraLarge: 1440px,
  wide: 2000px,
);
// breakpoints:end

// fonts:start
$font--primary: "Inter";
// fonts:end

// font-weights:start
$weights: (
  100: "100",
  300: "300",
  400: "400",
  500: "500",
  600: "600",
  700: "700",
);
// font-weights:end
