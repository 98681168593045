.color {
  width: 100%;
  .color__subtitle {
    margin-top: 72px;
    margin-bottom: 40px;

    @include response(medium) {
      margin-top: 32px;
      margin-bottom: 16px;
    }
  }

  .color__box {
    width: 100%;
    margin-bottom: 16px;
    padding: 40px 24px;
    margin-right: 0;
    margin-left: 0;
    @include response(medium) {
      width: 728px;
    }

    @include response(large) {
      padding: 40px 40px 32px;
      width: 832px;
    }

    .col-12 {
      padding: 0;
    }

    .box__color {
      p {
        font-size: 20px;
        line-height: 29px;

        @include response(large) {
          font-size: 24px;
        }

        &:nth-child(1) {
          font-weight: 500;
          margin-bottom: 4px;
        }
      }
    }

    .box__size {
      margin-top: 40px;
      @include response(small) {
        margin-top: 0px;
      }
      .item {
        max-width: 415px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 0;
        line-height: 24px;
        border-bottom: 1px solid $stout;
        white-space: nowrap;

        @include response(medium) {
          float: right;
        }

        &--first {
          padding-top: 0px;
        }

        &--last {
          padding-bottom: 0px;
        }

        &:last-child {
          border-bottom: 0;
        }

        p {
          width: 33.3%;
          padding: 0 48px;
          font-size: 16px;
          line-height: 22px;
          &:first-child {
            padding-left: 0px;
          }
        }
      }
      @include response(medium) {
        margin-top: 0;
      }

      .item--stout {
        border-bottom: 1px solid white;
      }
    }
  }

  .neutrals {
    margin-top: 80px;
    margin-bottom: 40px;
    @include response(medium) {
      margin-bottom: 16px;
    }
  }
  .n-white {
    border: 1px solid $midnight;
  }

  .n-cloudy {
    background-color: $cloudy;
  }
}
