.audio-card {
  padding: 16px 16px 40px 16px;
  background-color: $midnight;
  border-radius: 8px;
  margin-top: 48px;
  color: white;

  .row {
    * {
      padding-right: 0;
      padding-left: 0;
    }

    .col-12 {
      padding-right: 0;
      padding-left: 0;
    }
  }

  @include response(medium) {
    padding: 32px 22px;
  }

  @include response(large) {
    margin-top: 40px;
    padding: 32px;
  }

  audio {
    display: none;
  }

  .card__image {
    width: 100%;
    position: relative;
    background-size: cover;
    background-color: $indiblue;
    border-radius: 5px;
    display: flex;

    @include response(medium) {
      max-width: 234px;
      height: 234px;
    }

    .audio__time {
      position: absolute;
      right: 19px;
      top: 24px;
      font-size: 18px;
      line-height: 140%;
    }

    .image__mobile {
      max-width: 311px;
      width: 100%;
      @include response(medium) {
        display: none;
      }
    }

    .image__desktop {
      display: none;
      @include response(medium) {
        display: block;
        height: 234px;
      }
    }

    .card__play {
      width: 52px;
      height: 52px;
      position: absolute;
      right: 19px;
      bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $midnight;
      border-radius: 50%;
      border: 0;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    }
  }

  .audio__list {
    @include response(medium) {
      padding-left: 32px;
      padding-right: 0;
    }

    .audio__title {
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;

      a {
        &:hover {
          * {
            stroke: white;
          }
        }
        @include response(medium) {
          margin-bottom: 20px;
        }
      }
    }

    h3 {
      @include response(medium) {
        margin-top: 0px;
      }
    }
    p {
      margin-bottom: 22px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.audio__description {
  margin-top: 24px;
}
