.backtotop {
  .button {
    background-color: white;
    border: 0px;
    white-space: nowrap;
    color: $indiblue;
    font-size: 18px;
    padding: 0;
  }
}
