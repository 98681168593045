.do-donts {
  @include response(medium) {
    padding-right: 35px;
  }
  @include response(large) {
    padding-right: 0px;
  }
  .do-donts__table {
    .table__title {
      margin-top: 40px;
      margin-bottom: 0;
      font-weight: 700;
      border-bottom: 2px solid;

      &.table__title--indiblue {
        color: $indiblue;
        border-color: $indiblue;
      }

      &.table__title--dont {
        color: $dont;
        border-color: $dont;
      }
    }

    .item {
      padding: 8px 0;
      border-bottom: 1px solid $cloudy;
      box-sizing: content-box;

      @include response(medium) {
        &.item--2 {
          height: calc(26px * 2);
        }

        &.item--3 {
          height: calc(26px * 3);
        }
      }
    }
  }
}
